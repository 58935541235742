"use client";

import { useAppContext } from "@/context/AppContextProvider";
import { useSession } from "next-auth/react";
import Link from "next/link";
import { FC, useEffect, useState } from "react";
import SignInButton from "@/components/SignInButton";
import Image from "next/image";
import { useUserGetProfile } from "@/hooks/user/useUserGetProfile";
import ImageIconComponent from "@/components/icons/ImageIconComponent";
import { URLConstants } from "@/constants/URLConstants";
import { useRouter } from "next/navigation";
import { BREAK_POINT_CONSTANTS } from "@/constants/AppConstants";


const HomeHeader: FC<{ userIconClassName?: string; }> = ({ userIconClassName = '' }) => {
  const { visableMode, setScreenType } = useAppContext();
  const { data: session } = useSession();
  const router = useRouter();
  const user = session?.user;
  const [windowWidth, setWindowWidth] = useState(0);
  const { data: userProfile, isLoading, isError } = useUserGetProfile({
    userUuid: user?.id ?? ''
  });

  useEffect(() => {
    if (typeof window === undefined) return;

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // イベントリスナーを登録
    window.addEventListener("resize", handleResize);

    // クリーンアップ関数（イベントリスナーの解除）
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (isLoading || isError) {
    return <></>;
  }

  return (
    <header
      className={`fixed z-[100] top-0 w-full min-w-screen-sm bg-opacity-50 settingArea ${visableMode.isShow ? "fade-in" : "fade-out"}`}
    >
      <div className="min-h-[50px] p-[10px] flex justify-between">
        <div
          className="max-w-28"
        >
          <Link className="text-white" href="/">
            <h1 className="logoBox">
              <Image
                alt="picsle logo"
                src="/picsle_logo.svg"
                width={300}
                height={300}
                style={{ width: "100%", height: "auto" }}
                fill={false}
                priority={false}
              />
            </h1>
          </Link>
        </div>
        <div
        >{user && userProfile ? (
          <div
            className="cursor-pointer"
            onClick={(e) => { e.stopPropagation(); setScreenType('grid'); router.push(URLConstants.user.mypage.pathname) }}
          >
            <ImageIconComponent
              className={userIconClassName}
              size={{
                width: windowWidth <= BREAK_POINT_CONSTANTS.SM ? 30 : 50,
                height: windowWidth <= BREAK_POINT_CONSTANTS.SM ? 30 : 50,
              }}
              fileURL={userProfile.userIconFile?.fileUrl ?? ''}
            />
          </div>

        ) : <SignInButton />}</div>
      </div>
    </header>
  );
};

export default HomeHeader;
