"use client";

import useSWR, { mutate } from "swr";
import { UserAPI, UserGetRequest, UserProfile } from "@/restapi/user";
import { useEffect } from "react";

export const useUserGetProfile = (request: UserGetRequest) => {
  const { data, error } = useSWR(
    `user-profile-${JSON.stringify(request)}`,
    () => UserAPI.get(request),
  );

  useEffect(() => {
    // 10秒ごとにデータを手動で再フェッチ
    const intervalId = setInterval(() => {
      mutate(`user-profile-${request.userUuid}`); // 手動でキャッシュを更新
    }, 10000);

    // コンポーネントのアンマウント時にインターバルをクリア
    return () => clearInterval(intervalId);
  }, [request.userUuid]);


  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};
