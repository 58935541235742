"use client";

import Home from "@/components/Home";
import { useEffect } from "react";

export default function Component() {
  // リサイズイベントを発火して画面のレイアウトを調整
  useEffect(() => {
    setTimeout(() => {
      if (window !== undefined) {
        window.dispatchEvent(new Event("resize"));
      }
    }, 100);
  }, []);

  return (
    <div>
      <Home />
    </div>
  );
}
