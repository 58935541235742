import { BackendURLContantas } from "@/constants/URLConstants";
import { axios } from "@/lib/axios";

import { AxiosResponse } from "axios";
import { RankingImageData } from "../ranking";
import { Locale } from "@/lib/react/i18n/types/locale";
import { User } from "next-auth";

export type ScorePostEventResponse = {
  imageId: string;
  userId: string;
  posts: number;
  averageScore: number | null;
  totalScore: number;
};

export const GENDER_TYPE = {
  NO_SELECTED: -1,
  NONBINARY: 0,
  MALE: 1,
  FEMALE: 2,
} as const;

export type UserHiddenNameEventResponse = {
  hiddenName: boolean;
  userId: string;
};

export type UserIconFile = {
  id: number;
  userUuid: string;
  fileUrl: string;
  fileName: string;
};

export type UserHideNameRequest = {
  userId: string;
  hideName: boolean;
};

export type UserHideNameResponse = {
  userUuid: string;
  hideName: boolean;
};

export type UserPostRequest = {
  name: string;
  email: string;
  password: string;
  pref: string;
  country: string;
  age?: number;
  gender?: number;
};

export type UserPostResponse = {
  message: string;
  name: string;
  email: string;
  token: string;
};

export type UserScoreRequest = {
  imageId: string;
  score: number;
  ownerFlg: boolean;
  userId: string;
  scorerNameHidden: boolean;
};

export type UserIconUpdateRequest = {
  file: File;
  uuid: string;
};

export type UserProfileUpdateRequest = {
  file?: string | null;
  mimeType?: string | null;
  fileName?: string | null;
  email?: string;
  uuid: string;
  testimonials?: string;
  hideName?: boolean;
  name?: string;
  url1?: string;
  url2?: string;
  country?: string | null;
  pref?: string | null;
  birthday?: string | null;
  gender?: number | null;
};

type UserScoreResponse = {
  userAverageScore: number;
  userTotalScore: number;
  userPosts: number;
  imageTotalScore: number;
  imageAverageScore: number;
  imagePosts: number;
};

type UserProfileUpdateResponse = {
  status: number;
};


export type UserIconResponse = {
  fileUrl: string | null;
  fileName: string | null;
  userId: string | null;
  status: number;
};

export type UserProfile = {
  id: number;
  userId: string;
  userIconFileId: number | null;
  email: string;
  password: string;
  url1?: string;
  url2?: string;
  uuid: string;
  testimonials: string | null;
  hideName: boolean | null;
  name: string;
  userIconFile?: UserIconFile;
  createdAt: string;
  updatedAt: string;
  country: string;
  pref: string;
  birthday: string | null;
  locale?: Locale | null;
  gender?: number;
};

export type UserHistoryData = {
  id: number;
  user: User;
  following: boolean;
  userUuid: string;
  imageUuid: string;
  ownerFlag: number;
  timestamp: number;
  score: number;
  scorerNameHidden: boolean;
  createdAt: string;
  updatedAt: string;
  image: RankingImageData;
  userProfile: UserProfile;
};

export type UserHistoryResponse = {
  data: UserHistoryData[];
  currentPage: number;
  lastPage: number;
  total: number;
};

export type UserFollowRequest = {
  userId: string;
  followingUserId: string;
};



export type UserFollowResponse = {
  following: boolean;
};

export type UserGetRequest = {
  userUuid: string;
};


export type UserExistsUserIdRequest = {
  userId: string;
};


export type UserExistsUserIdResponse = {
  exists: boolean;
  messages: string[];
};

export type UserGetResponse = UserProfile;

const valideUserScoreResponse = (arg: unknown): boolean => {
  const res = arg as UserScoreResponse;

  return (
    (typeof res.userAverageScore === "number" ||
      res.userAverageScore === null) &&
    (typeof res.userTotalScore === "number" || res.userTotalScore === null) &&
    typeof res.userPosts === "number" &&
    typeof res.imageAverageScore === "number" &&
    typeof res.imageTotalScore === "number" &&
    typeof res.imageTotalScore === "number"
  );
};

type UserTotalAndAvgResponse = {
  totalScore: number | null;
  averageScore: number | null;
  posts: number;
};

export type UserSearchRequest = {
  userId: string;
}

export type UserSearchResponse = {
  data: UserProfile[];
}

export type PasswordChangeRequest = {
  password: string;
  userId: string;
}


export type PasswordChangeResponse = {
  message: string;
  status: number;
}

// ユーザー情報のLaravel用API
export const UserAPI = {
  post: async (userPostRequest: UserPostRequest): Promise<UserPostResponse> => {
    const { data } = await axios.post<
      UserPostRequest,
      AxiosResponse<UserPostResponse>
    >(BackendURLContantas.USER.POST.PATH_NAME, userPostRequest);

    return data;
  },
  totalAndAvg: async (userId: string) => {
    const { data } = await axios.get<
      unknown,
      AxiosResponse<UserTotalAndAvgResponse>
    >(`${BackendURLContantas.USER.TOTAL_AND_AVG.PATH_NAME}/${userId}`);

    return data;
  },
  score: async (
    userScoreRequest: UserScoreRequest
  ): Promise<UserScoreResponse> => {
    const { data } = await axios.post<
      UserScoreRequest,
      AxiosResponse<UserScoreResponse>
    >(BackendURLContantas.SCORE.PATH_NAME, userScoreRequest);

    return data;
  },
  history: async (userId?: string, page: number = 1) => {
    const { data } = await axios.get<
      unknown,
      AxiosResponse<UserHistoryResponse>
    >(`${BackendURLContantas.USER.HISTORY.PATH_NAME}/${userId}?page=${page}`);

    return data;
  },
  search: async (
    request: UserSearchRequest
  ): Promise<UserSearchResponse> => {
    const { data } = await axios.post<
      UserSearchRequest,
      AxiosResponse<UserSearchResponse>
    >(BackendURLContantas.USER.SEARCH.PATH_NAME,
      request,
    );

    return data;
  },
  update: async (
    userProfileUpdateRequest: UserProfileUpdateRequest
  ): Promise<UserProfileUpdateResponse> => {
    const { data } = await axios.post<
      UserProfileUpdateRequest,
      AxiosResponse<UserProfileUpdateResponse>
    >(BackendURLContantas.USER.UPDATE.PATH_NAME,
      JSON.stringify(userProfileUpdateRequest), {
      headers: { 'Content-Type': 'application/json' },
      maxBodyLength: Infinity,
    });

    return data;
  },
  updateHideName: async (
    request: UserHideNameRequest
  ): Promise<UserHideNameResponse> => {
    const { data } = await axios.post<
      UserHideNameRequest,
      AxiosResponse<UserHideNameResponse>
    >(BackendURLContantas.USER.UPDATE.HIDE_NAME.PATH_NAME, request);

    return data;
  },
  get: async (request: UserGetRequest) => {
    const { data } = await axios.post<
      UserGetRequest,
      AxiosResponse<UserGetResponse>
    >(`${BackendURLContantas.USER.GET.PATH_NAME}`, request);

    return data;
  },
  getIcon: async (userId: string) => {
    const { data } = await axios.get<unknown, AxiosResponse<UserIconResponse>>(
      `${BackendURLContantas.USER.ICON.PATH_NAME}/${userId}`
    );

    return data;
  },
  iconUpdate: async (userIconUpdateRequest: UserIconUpdateRequest) => {
    const { file, uuid } = userIconUpdateRequest;
    const formData = new FormData();

    formData.append("file", file);
    formData.append("uuid", uuid);

    const { data } = await axios.post<
      UserIconUpdateRequest,
      AxiosResponse<UserIconResponse>
    >(BackendURLContantas.USER.ICON.UPDATE.PATH_NAME, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });

    return data;
  },
  follow: async (userFollowRequest: UserFollowRequest) => {
    try {
      const { data } = await axios.post<
        UserFollowRequest,
        AxiosResponse<UserFollowResponse>
      >(BackendURLContantas.USER.FOLLOWED.PATH_NAME, userFollowRequest);

      return data;
    } catch (error: any) {
      console.error(error);
    }
  },
  following: async (userFollowRequest: UserFollowRequest) => {
    try {
      const { data } = await axios.post<
        UserFollowRequest,
        AxiosResponse<UserFollowResponse>
      >(BackendURLContantas.USER.FOLLOWING.PATH_NAME, userFollowRequest);

      return data;
    } catch (error: any) {
      console.error(error);
    }
  },
  existsUserId: async (request: UserExistsUserIdRequest) => {
    try {
      const { data } = await axios.post<
        UserExistsUserIdRequest,
        AxiosResponse<UserExistsUserIdResponse>
      >(BackendURLContantas.USER.EXISTS_USER_ID.PATH_NAME, request);

      return data;
    } catch (error: any) {
      console.error(error);
    }
  },
  changePassword: async (request: PasswordChangeRequest) => {
    const { data } = await axios.post<PasswordChangeRequest,
      AxiosResponse<PasswordChangeResponse>>(`${BackendURLContantas.USER.CHANGE_PASSWORD.PATH_NAME}`, request);
    return data;
  }
};
