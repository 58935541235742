import { Button } from "@/components/ui/button";
import Link from "next/link";

const SignInButton = () => {
  return (
    <div className="mr-4">
      <Link
        className="z-50 text-white rounded-full"
        href="/access-denied"
      >
        <Button variant="default" size="default">
          Login
        </Button>
      </Link>
    </div>
  );
};

export default SignInButton;
