"use client";

import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSession } from "next-auth/react";
import { useNotifyGetReadNewPostedImage } from "@/hooks/notification/useNotification";
import { NOTIFICATION_TYPE_VALUE } from "@/restapi/notification";
import { useTranslation } from "@/lib/react/i18n";
import GallaryTabSwitcher from "./features/gallery/tabs/GallaryTabSwitcher";
import { FILTER_IMAGE_CATEGORY_TYPE, isValidFilterImageCategory, PAGE_CATEGORY_KEYS, TabItem, useGalleryCategory } from "./features/gallery/hooks/useGalleryCategory";
import FullScreenGalleryHeader from "./features/gallery/FullScreenGallery/components/FullScreenGalleryHeader";
import Footer from "./Footer";
import HomeHeader from "./organisms/header/HomeHeader";
import InfiniteScrollGallery from "./features/gallery/InfiniteScrollGallery";
import { useAppContext } from "@/context/AppContextProvider";
import { useRouter, useSearchParams } from "next/navigation";
import { URLConstants } from "@/constants/URLConstants";

export default function Home() {
  const { t } = useTranslation();
  const { screenType, setScreenType } = useAppContext();
  const { data: session } = useSession();
  const user = session?.user;
  const userId = user?.id ?? "";
  const searchParams = useSearchParams();
  const category = searchParams.get('category');
  const router = useRouter();

  // 通知をリセットして通知件数を0にする
  useNotifyGetReadNewPostedImage({
    userId,
    notificationType: NOTIFICATION_TYPE_VALUE.NEW_POSTED_IMAGE,
  });

  // 使いたいタブの定義をしてタブ選択項目を画面表示させる
  // タブの基本定義
  const tabs: TabItem[] = [
    {
      label: t("Latest"), value: FILTER_IMAGE_CATEGORY_TYPE.LATEST,
      clickTab: () => {
        if (typeof window !== 'undefined') {
          if (activeCategory !== FILTER_IMAGE_CATEGORY_TYPE.LATEST) {
            window.location.href = `${URLConstants.image.latest.pathname}`
          }
        }
      }
    },
  ];

  const handleClickLeftIcon = () => {
    if (typeof window !== 'undefined') {
      window.location.href = `${URLConstants.home.pathname}?category=${activeCategory}`;
    }
  }

  // 初期値はgridになっているのでHomeのみランダム画像一覧にする
  useLayoutEffect(() => {
    setScreenType('random');
  }, []);

  // リサイズイベントを発火して画面のレイアウトを調整
  useEffect(() => {
    setTimeout(() => {
      if (window !== undefined) {
        window.dispatchEvent(new Event("resize"));
      }
    }, 100);
  }, []);


  // ログイン中の場合のみ「Following」を追加
  if (user) {
    tabs.push({
      label: t("Following"), value: FILTER_IMAGE_CATEGORY_TYPE.FOLLOWING, clickTab: () => {
        if (typeof window !== 'undefined') {
          if (activeCategory !== FILTER_IMAGE_CATEGORY_TYPE.FOLLOWING) {
            window.location.href = `${URLConstants.image.following.pathname}`
          }
        }
      }
    });
  }

  // タブ表示制御のhooks関数
  const { request, activeCategory, handleCategoryChange } = useGalleryCategory(
    {
      initFilterImageCategory: isValidFilterImageCategory(category) ? category : FILTER_IMAGE_CATEGORY_TYPE.LATEST,
      requestUserId: userId,
      pageCategory: PAGE_CATEGORY_KEYS.HOME
    });

  // クエリパラメータを削除する関数
  const removeCategoryQuery = () => {
    if (!searchParams.has("category")) return;

    const params = new URLSearchParams(searchParams.toString());
    params.delete("category");

    const newPath = `${window.location.pathname}${params.toString() ? `?${params.toString()}` : ""}`;
    router.replace(newPath, { scroll: false });
  };

  useEffect(() => {
    if (searchParams.has("category")) {
      removeCategoryQuery();
    }
  }, [searchParams]);

  if (!request) {
    return <></>;
  }

  return (
    <div className="pc-container bg-black min-h-screen my-10">
      {/* フルスクリーンと通常のヘッダー部分の機能と表示が違うので切り分け表示 */}
      {screenType === 'fullscreen' ? <FullScreenGalleryHeader user={user} gallaryCategory={activeCategory} handleClickLeftIcon={handleClickLeftIcon}
        tabs={tabs}
      /> : <HomeHeader />}
      <div className="mb-20">
        <div className="mt-20 flex justify-center items-center">
          <div className="w-full">
            {/* フルスクリーンはヘッダーにタブ表示されてるが、他のコンポーネントはこのタブを表示させる */}
            {
              screenType !== 'fullscreen' && <GallaryTabSwitcher tabs={tabs} category={activeCategory} handleCategoryChange={handleCategoryChange} className="max-w-[800px] mx-auto justify-around" buttonClassName="mx-8" />
            }
            {/* 画像一覧 */}
            <div className={`text-white flex justify-center`}>
              <InfiniteScrollGallery
                screenType={screenType}
                changeScreenType={setScreenType}
                gallaryCategory={activeCategory}
                request={request}
                defaultScreenType="random"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
